import CalvientVapor from './CalvientVapor';

const vapor = new CalvientVapor();
// @ts-ignore
vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);

const assetUrl = (url: string): string => {
  if (url.startsWith('/')) {
    return vapor.asset(url.substr(1));
  }
  return vapor.asset(url);
};

export default assetUrl;
